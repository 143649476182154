<template>
  <!--
    The view for the Reporting-component
  -->
  <div
    class="reportingView"
  >
    <LoadingPlaceholder v-if="loading" />
    <template
      v-else
    >
      <div
        v-if="resortId != -1"
        class="form-group row mb-5"
      >
        <label
          for="installations"
          class="col-sm-1 col-form-label"
        >{{ $t('installations') }}</label>
        <select
          id="installations"
          v-model="selectedInstallation"
          class="form-control col-sm-5 mt-2"
        >
          <option
            :value="null"
          >
            {{ 'All Installations' }}
          </option>
          <option
            v-for="(installation, index) in installations"
            :key="`installationSelection-installationSelectOption-${ index }`"
            :value="installation"
          >
            {{ installation.name }}
          </option>
        </select>
      </div>
      <template v-if="photopointResponse || skimovieResponse || phototrapResponse || speedcheckResponse || photostartResponse || skimovieSpotResponse.length > 1 || phototrapSpotResponse.length > 1">
        <template
          v-if="skimovieSpotResponse && skimovieSpotResponse.length > 1"
        >
          <div
            id="nav-tab"
            class="nav nav-tabs"
            role="tablist"
          >
            <a
              v-for="(key, index) in skimovieSpotResponse"
              :id="`spoT${ index }-tab`"
              :key="`Report-tab-listItem-${ index }`"
              :class="['nav-item nav-link', {'active': index == 0}]"
              data-toggle="tab"
              :href="`#spoT${ index }`"
              role="tab"
              :aria-controls="`spoT${ index }`"
              :aria-selected="index === 0"
            >Spot: {{ key.spotId }}</a>
          </div>
          <div
            id="nav-tabContent"
            class="tab-content"
          >
            <div
              v-for="(val, index) in skimovieSpotResponse"
              :id="`spoT${ index }`"
              :key="`Report-spot-listItem-${ index }`"
              :class="['reports-pane tab-pane fade show', {'active': index == 0}]"
              role="tabpanel"
              :aria-labelledby="`spoT${ index }-tab`"
            >
              <ReportByType 
                :ref="'raceReport' + val.spotId"
                class="card"
                :portlet-title="`Skimovie - ${ skimovieInstallation.name }`"
                :chart-data="val.response"
              />
            </div>
          </div>
        </template>
        <template
          v-else-if="skimovieResponse"
        >
          <ReportByType
            ref="raceReport"
            class="card"
            :portlet-title="`Skimovie ${ skimovieInstallation != null ? skimovieInstallation.name : ''} ${skimovieCount > 0 ? ' - ('+ skimovieCount +' In Operation)' : ''}`"
            :chart-data="skimovieResponse"
          />
        </template>
        <template
          v-if="phototrapSpotResponse && phototrapSpotResponse.length > 1"
        >
          <div
            id="nav-tab"
            class="nav nav-tabs"
            role="tablist"
          >
            <a
              v-for="(key, index) in phototrapSpotResponse"
              :id="`p-spoT${ index }-tab`"
              :key="`p-Report-tab-listItem-${ index }`"
              :class="['nav-item nav-link', {'active': index == 0}]"
              data-toggle="tab"
              :href="`#p-spoT${ index }`"
              role="tab"
              :aria-controls="`p-spoT${ index }`"
              :aria-selected="index === 0"
            >Spot: {{ key.spotId }}</a>
          </div>
          <div
            id="nav-tabContent"
            class="tab-content"
          >
            <div
              v-for="(val, index) in phototrapSpotResponse"
              :id="`p-spoT${ index }`"
              :key="`p-Report-spot-listItem-${ index }`"
              :class="['reports-pane tab-pane fade show', {'active': index == 0}]"
              role="tabpanel"
              :aria-labelledby="`p-spoT${ index }-tab`"
            >
              <ReportByType
                :ref="'trapReport' + val.spotId"
                class="card"
                :portlet-title="`Skimovie - ${ phototrapInstallation.name }`"
                :chart-data="val.response"
              />
            </div>
          </div>
        </template>
        <template
          v-else-if="phototrapResponse"
        >
          <ReportByType
            ref="trapReport"
            class="card"
            :portlet-title="`Phototrap ${ phototrapInstallation != null ? phototrapInstallation.name : ''} ${phototrapCount > 0 ? ' - ('+ phototrapCount +' In Operation)' : ''}`"
            :chart-data="phototrapResponse"
          />
        </template>
        <ReportByType
          v-if="speedcheckResponse"
          ref="speedReport"
          class="card"
          :portlet-title="`Speedcheck ${ speedcheckInstallation != null ? speedcheckInstallation.name : ''} ${speedcheckCount > 0 ? ' - ('+ speedcheckCount +' In Operation)' : ''}`"
          :chart-data="speedcheckResponse"
        />
        <ReportByType
          v-if="photostartResponse"
          ref="photoReport"
          class="card"
          :portlet-title="`Photostart ${ photostartInstallation != null ? photostartInstallation.name : ''} ${photostartCount > 0 ? ' - ('+ photostartCount +' In Operation)' : ''}`"
          :chart-data="photostartResponse"
        />
        <ReportByType
          v-if="photopointResponse"
          ref="photopointReport"
          class="card"
          :portlet-title="`Photopoint ${ photopointInstallation != null ? photopointInstallation.name : ''} ${photopointCount > 0 ? ' - ('+ photopointCount +' In Operation)' : ''}`"
          :chart-data="photopointResponse"
        />
      </template>
      <p v-else>
        {{ !isInstallationSelected ? $t('noResortReports') : $t('noInstallationReports') }}
      </p>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "ReportingView",
  metaInfo () {
    return {
      title: this.$t('menu.reporting')
    }
  },
  components: {
    ReportByType: () => import('@/components/Reporting/ReportByType.vue'),
  },
  mixins: [
    dateTimeMixin,
  ],
  props: {
    resortId: {
      type: Number,
      required: true,
      default () {
        return -1;
      }
    },
    installations: {
      type: Array,
      required: false,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      skimovieCount: 0,
      phototrapCount: 0,
      speedcheckCount: 0,
      photostartCount: 0,
      photopointCount: 0,
      skimovieResponse: null,
      speedcheckResponse: null,
      phototrapResponse: null,
      photostartResponse: null,
      photopointResponse: null,
      skimovieSpotResponse: [],
      phototrapSpotResponse: [],
      skimovieInstallation: null,
      phototrapInstallation: null,
      speedcheckInstallation: null,
      photostartInstallation: null,
      photopointInstallation: null,
      loading: false,
      isInstallationSelected: false,
      selectedInstallation: null
    }
  },
  computed: {
    installationTypes () {
      return ['Skimovie,Biketrack', 'Phototrap', 'Speedcheck', 'Photostart', 'Photopoint'];
    }
  },
  watch: {
    resortId (val) {
      if(val == -1) {
        this.getReports();
      }
      else {
        this.selectResort(val);
      }
    },
    selectedInstallation (val) {
      if(val != null) {
        this.selectResortInstallation(val);
      }
      else {
        this.selectResort(this.resortId);
      }
    }
  },
  created () {
    if(this.resortId == -1) {
      this.getReports();
    }
    else {
      this.selectResort(this.resortId);
    }
  },
  methods: {
    getReports () {
      this.isInstallationSelected = false;
      this.skimovieResponse = null;
      this.phototrapResponse = null;
      this.speedcheckResponse = null;
      this.photostartResponse = null;
      this.photopointResponse = null;
      this.skimovieSpotResponse = [];
      this.phototrapSpotResponse = [];
      this.skimovieCount = -1;
      this.phototrapCount = -1;
      this.speedcheckCount = -1;
      this.photostartCount = -1;
      this.photopointCount = -1;
      this.loading = true;
      this.installationTypes.forEach((x, i, arr) => {
        this.axios.get(`/Media/GetReportsByInstallationType/${ x }`)
        .then((response) => {
          let isOk = false;
          if (response) {
            try {
              response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  
              isOk = true;
            } catch (error) {
                isOk = false;
            }
          } 
          if (x.includes('Skimovie')) {
            this.skimovieResponse = isOk ? response : null;
            this.skimovieSpotResponse = [];
            this.skimovieInstallation = null;
          }
          else if (x == 'Phototrap') {
            this.phototrapResponse = isOk ? response : null;
            this.phototrapSpotResponse = [];
            this.phototrapInstallation = null;
          }
          else if (x == 'SpeedCheck' || x == 'Speedcheck') {
            this.speedcheckResponse = isOk ? response : null;
            this.speedcheckInstallation = null;
          }
          else if (x == 'Photopoint') {
            this.photopointResponse = isOk ? response : null;
            this.photopointInstallation = null;
          }
          else {
            this.photostartResponse = isOk ? response : null;
            this.photostartInstallation = null;
          }
          this.getResortInstallationsCount(x, null);
          
          if(i === arr.length -1) {
            this.loading = false;
          }
        });
      });
    },
    selectResort (resortId) {
      this.isInstallationSelected = false;
      this.skimovieResponse = null;
      this.phototrapResponse = null;
      this.speedcheckResponse = null;
      this.photostartResponse = null;
      this.photopointResponse = null;
      this.skimovieSpotResponse = [];
      this.phototrapSpotResponse = [];
      this.skimovieCount = -1;
      this.phototrapCount = -1;
      this.speedcheckCount = -1;
      this.photostartCount = -1;
      this.photopointCount = -1;
      this.loading = true;
      this.installationTypes.forEach((x, i, arr) => {
        this.axios.get(`/Media/GetReportsByResortIdAndInstallationType/${ resortId }/${ x }`)
        .then((response) => {
          let isOk = false;
          if (response) {
            try {
              response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  
              isOk = true;
            } catch (error) {
                isOk = false;
            }
          } 
          if (x.includes('Skimovie')) {
            this.skimovieResponse = isOk ? response : null;
            this.skimovieSpotResponse = [];
            this.skimovieInstallation = null;
          }
          else if (x == 'Phototrap') {
            this.phototrapResponse = isOk ? response : null;
            this.phototrapSpotResponse = [];
            this.phototrapInstallation = null;
          }
          else if (x == 'SpeedCheck' || x == 'Speedcheck') {
            this.speedcheckResponse = isOk ? response : null;
            this.speedcheckInstallation = null;
          }
          else if (x == 'Photopoint') {
            this.photopointResponse = isOk ? response : null;
            this.photopointInstallation = null;
          }
          else {
            this.photostartResponse = isOk ? response : null;
            this.photostartInstallation = null;
          }

          this.getResortInstallationsCount(x, resortId);
          if(i === arr.length -1) {
            this.loading = false;
          }
        });
      });
    },
    selectResortInstallation (installation) {
      this.isInstallationSelected = true;
      this.skimovieResponse = null;
      this.phototrapResponse = null;
      this.speedcheckResponse = null;
      this.photostartResponse = null;
      this.photopointResponse = null;
      this.skimovieSpotResponse = [];
      this.phototrapSpotResponse = [];
      this.skimovieCount = -1;
      this.phototrapCount = -1;
      this.speedcheckCount = -1;
      this.photostartCount = -1;
      this.photopointCount = -1;
      this.loading = true;
      let type = installation.installationType;
      if(type == 'Phototrap' || type == 'Skimovie' || type == 'Biketrack') {
        this.axios.get('/Media/GetSpotIds/' + installation.id)
        .then((response) => {
          let spotIds = response.data;
          if(spotIds.length > 1) {
            spotIds.forEach((x, i ,arr) => {
              this.axios.get(`/Media/GetReportsBySpotId/${ x }`)
              .then((response) => {
                let isOk = false;
                if (response) {
                  try {
                    response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  
                    isOk = true;
                  } catch (error) {
                      isOk = false;
                  }
                } 
                if (type.includes('Skimovie')) {
                  if(isOk) {
                    this.skimovieSpotResponse.push({'response': response, 'spotId': x });
                  }
                  this.skimovieResponse = null;
                  this.skimovieCount = -1;
                  this.skimovieInstallation = installation;
                }
                else if (type == 'Phototrap') {
                  if(isOk) {
                    this.phototrapSpotResponse.push({'response': response, 'spotId': x });
                  }
                  this.phototrapResponse = null;
                  this.phototrapCount = -1;
                  this.phototrapInstallation = installation;
                }
                if(i === arr.length -1) {
                  this.loading = false;
                }
              });
            })
          } else {
            this.axios.get(`/Media/GetReportsByInstallationId/${ installation.id }`).then((response) => {
            let isOk = false;
              if (response) {
                try {
                  response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  
                  isOk = true;
                } catch (error) {
                    isOk = false;
                }
              } 
              if (type.includes('Skimovie')) {
                this.skimovieResponse = isOk ? response : null;
                this.skimovieSpotResponse = [];
                this.skimovieCount = -1;
                this.skimovieInstallation = installation;
              }
              else if (type == 'Phototrap') {
                this.phototrapResponse = isOk ? response : null;
                this.phototrapSpotResponse = [];
                this.phototrapCount = -1;
                this.phototrapInstallation = installation;
              }
              this.loading = false;
            });
          }
        });
      } else {
        this.axios.get(`/Media/GetReportsByInstallationId/${ installation.id }`).then((response) => {
          let isOk = false;
            if (response) {
              try {
                response.data.map(o => this.$options.filters.dateTime_date(o.createDate));  
                isOk = true;
              } catch (error) {
                  isOk = false;
              }
            } 
            if (type == 'SpeedCheck' || type == 'Speedcheck') {
              this.speedcheckResponse = isOk ? response : null;
              this.speedcheckCount = -1;
              this.speedcheckInstallation = installation;
            }
            if (type == 'Photopoint') {
              this.photopointResponse = isOk ? response : null;
              this.photopointCount = -1;
              this.photopointInstallation = installation;
            }
            else {
              this.photostartResponse = isOk ? response : null;
              this.photoStartCount = -1;
              this.photostartInstallation = installation;
            }
            this.loading = false;
        });
      }
    },
    getResortInstallationsCount (type, resortId) {
      this.axios.get(`/Resort/GetResortInstallationsCount/${type}/${resortId}/`)
        .then((response) => {
          if (response) {
            this.installationsCount(response.data, type);
          }
        });
    },
    installationsCount (val, type) {
      if (type.includes('Skimovie')) {
        this.skimovieCount = val;
      }
      else if (type == 'Phototrap') {
        this.phototrapCount = val;
      }
      else if (type == 'SpeedCheck' || type == 'Speedcheck') {
        this.speedcheckCount = val;
      }
      else if (type == 'Photopoint') {
        this.photopointCount = val;
      }
      else {
        this.photostartCount = val;
      }
    }
  }
}
</script>

<style scoped>
.reports-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}
</style>